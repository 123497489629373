import React, { useEffect, useState } from 'react';

import { Box, Tab, Tabs } from '@mui/material';

import { DesktopScreen } from '@/components/utility/screen-prototypes/DesktopScreen';
import { MobileScreen } from '@/components/utility/screen-prototypes/MobileScreen';
import { TabletScreen } from '@/components/utility/screen-prototypes/TabletScreen';
import { WebsiteBuilderBackgrounds } from '@/constants/WebsiteBuilderBackgrounds';
import useArtist from '@/hooks/artist/useArtist';
import useBreakpoints from '@/hooks/utility/useBreakpoints';
import useWebsite from '@/hooks/website-builder/useWebsite';
import { WebsiteBuilderBackgroundColorModel, WebsiteBuilderBackgroundImageModel } from '@/models/WebsiteBuilder';

import FanHubPreviewContents from './FanHubPreviewContents';

export type ExtendedCSSProperties = React.CSSProperties & {
  '--card-background-color'?: string;
  '--text-color'?: string;
};

const FanHubTabbedPreview = () => {
  const { breakpointHitCustom } = useBreakpoints(680);
  const { artist } = useArtist();
  const { website } = useWebsite();

  const [fanHubTabValue, setFanHubTabValue] = useState<string>('desktop');

  const [chosenBackground, setChosenBackground] = useState<
    WebsiteBuilderBackgroundImageModel | WebsiteBuilderBackgroundColorModel
  >();
  const [style, setStyle] = useState<ExtendedCSSProperties>();

  const formThemeBackground = website?.theme.background;
  const formThemeBackgroundName =
    typeof website?.theme.background === 'object' ? website?.theme.background?.name : undefined;

  const formArtistName = website?.artistName || '';
  const formPrimaryLinks = website?.primaryLinks;
  const formBio = website?.bio;
  const formProfileImage = website?.profileImageUrl;

  const isImageModel = (
    background: WebsiteBuilderBackgroundImageModel | WebsiteBuilderBackgroundColorModel
  ): background is WebsiteBuilderBackgroundImageModel => 'url' in background;

  useEffect(() => {
    if (chosenBackground) {
      const style = {
        backgroundImage: isImageModel(chosenBackground) ? `url(${chosenBackground.url})` : undefined,
        backgroundColor: !isImageModel(chosenBackground) ? chosenBackground.hex : undefined,
        backgroundSize: 'cover',
      };
      setStyle(style);
    }
  }, [chosenBackground]);

  useEffect(() => {
    if (!breakpointHitCustom) {
      setFanHubTabValue('all');
    } else {
      setFanHubTabValue('desktop');
    }
  }, [breakpointHitCustom]);

  useEffect(() => {
    if (formThemeBackground || formThemeBackgroundName) {
      const { images, colors } = WebsiteBuilderBackgrounds;
      const allBackgrounds = [...images, ...colors];
      const matchedBackground = allBackgrounds.find(
        (item) => item.name === formThemeBackground || item.name === formThemeBackgroundName
      );
      if (matchedBackground) {
        setChosenBackground(matchedBackground);
      }
    }
  }, [formThemeBackground, formThemeBackgroundName]);

  const contents = (
    <FanHubPreviewContents
      artistName={formArtistName}
      image={formProfileImage || artist?.details.profileImageUrl || ''}
      primaryLinks={formPrimaryLinks}
      bio={formBio}
      chosenBackground={chosenBackground}
    ></FanHubPreviewContents>
  );

  if (!website) {
    return null;
  }

  return (
    <>
      <Box>
        <Tabs
          sx={{ justifyContent: 'center' }}
          value={fanHubTabValue}
          onChange={(_, value) => {
            setFanHubTabValue(value);
          }}
          className="tabs-center"
        >
          {!breakpointHitCustom && <Tab label="All" value="all" />}
          <Tab label="Desktop" value="desktop" />
          <Tab label="Mobile" value="mobile" />
          <Tab label="Tablet" value="tablet" />
        </Tabs>
      </Box>
      <div className="br24 p20" style={style}>
        {fanHubTabValue === 'all' && (
          <div className="pos-rel d-flex preview-group-container">
            <div className="mobile-group">
              <MobileScreen>{contents}</MobileScreen>
            </div>
            <div className="desktop-group">
              <DesktopScreen>{contents}</DesktopScreen>
            </div>
            <div className="tablet-group">
              <TabletScreen>{contents}</TabletScreen>
            </div>
          </div>
        )}
        {fanHubTabValue === 'desktop' && <DesktopScreen>{contents}</DesktopScreen>}
        {fanHubTabValue === 'mobile' && <MobileScreen>{contents}</MobileScreen>}
        {fanHubTabValue === 'tablet' && <TabletScreen>{contents}</TabletScreen>}
      </div>
    </>
  );
};

export default FanHubTabbedPreview;
