import React, { useEffect, useState } from 'react';

import { Button } from '@mui/material';

import {
  WebsiteBuilderBackgroundColorModel,
  WebsiteBuilderBackgroundImageModel,
  WebsiteBuilderPrimaryLinksModel,
} from '@/models/WebsiteBuilder';

import { ExtendedCSSProperties } from './WebsitePreviewPage';

export type FanHubPreviewContentsProps = {
  artistName: string;
  primaryLinks?: WebsiteBuilderPrimaryLinksModel[];
  bio?: string;
  image: string;
  chosenBackground?: WebsiteBuilderBackgroundImageModel | WebsiteBuilderBackgroundColorModel;
};

const FanHubPreviewContents = ({
  artistName,
  primaryLinks,
  bio,
  chosenBackground,
  image,
}: FanHubPreviewContentsProps) => {
  const [style, setStyle] = useState<ExtendedCSSProperties>();

  const isImageModel = (
    background: WebsiteBuilderBackgroundImageModel | WebsiteBuilderBackgroundColorModel
  ): background is WebsiteBuilderBackgroundImageModel => 'url' in background;

  useEffect(() => {
    if (chosenBackground) {
      const isLight = chosenBackground.name.includes('light');
      const style = {
        backgroundImage: isImageModel(chosenBackground) ? `url(${chosenBackground.url})` : undefined,
        backgroundColor: !isImageModel(chosenBackground) ? chosenBackground.hex : undefined,
        backgroundSize: 'cover',
        '--card-background-color': isLight ? '#fafafa72' : '#26262666',
        '--text-color': isLight ? '#383838' : '#fafafa',
      };
      setStyle(style);
    }
  }, [chosenBackground]);

  const logos = ['apple-music', 'deezer', 'facebook', 'instagram', 'spotify', 'tiktok', 'twitter', 'youtube'];

  return (
    <div className="website-preview mb20 m-auto mb300 text-center" style={style}>
      <div className="title-card">
        <h3>{artistName}</h3>
        <p>Powered by un:hurd music</p>
      </div>
      <div className="carousel-card">
        <img className="profile-pic" src={image || ''} alt="" />
        <p>{bio}</p>
        <div className="d-flex flex-wrap jc-center">
          {primaryLinks
            ?.filter((link) => link.customText !== undefined)
            .map((item, index) => <Button key={index}>{item.customText}</Button>)}
        </div>
      </div>
      <div className="socials-card">
        {logos.map((item) => (
          <div key={item} className="socials-container">
            <img
              className={`${
                (item === 'twitter' || item === 'apple-music') && chosenBackground?.name.includes('light')
                  ? 'invert'
                  : ''
              }`}
              src={`/images/logos/${item}-logo.svg`}
              alt=""
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default FanHubPreviewContents;
