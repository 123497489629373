import React from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import CustomIcons from '@/components/utility/microcomponents/CustomIcons';
import { WebsiteBuilderModel } from '@/models/WebsiteBuilder';

const SpotlightReleasePage = () => {
  const { t } = useTranslation();

  const { setValue, watch } = useFormContext<WebsiteBuilderModel>();
  const formCustomText = watch('spotlightRelease.customText');
  const formSpotifyLink = watch('spotlightRelease.spotifyLink');
  const formAppleLink = watch('spotlightRelease.appleMusicLink');
  const formItunesLink = watch('spotlightRelease.itunesLink');
  const formYoutubeLink = watch('spotlightRelease.youTubeMusicLink');
  const formAmazonLink = watch('spotlightRelease.amazonMusicLink');
  const formDeezerLink = watch('spotlightRelease.deezerLink');

  return (
    <>
      <h3>{t('WEBSITE-BUILDER.SPOTLIGHT-RELEASE')}</h3>
      <p className="text-faded mb48 mb20-lg-down mt10">{t('WEBSITE-BUILDER.SPOTLIGHT-RELEASE-DESCRIPTION')}</p>
      <div className="card mb20 m-auto mb300">
        <div className="d-flex gap20 flex-wrap">
          <div className="flex-w40p w100p-lg-down text-left">
            <h3>{t('WEBSITE-BUILDER.YOUR-MESSAGE')}</h3>
            <p className="text-faded mt8">{t('WEBSITE-BUILDER.YOUR-MESSAGE-DESCRIPTION')}</p>
          </div>
          <div className="flex-w60p w100p-lg-down text-left">
            <div className="pos-rel">
              <textarea
                placeholder={t('WEBSITE-BUILDER.STREAM-OUR-NEW-RELEASE')}
                value={formCustomText ?? 'Listen to my latest release on your chosen platform below'}
                maxLength={100}
                onChange={(event) => setValue('spotlightRelease.customText', event.target.value)}
              />
              <div className="textarea-counter">{formCustomText?.length ?? 0}/100</div>
            </div>
          </div>
        </div>
        <div className="d-flex form-divider mt10 mb20">
          <div className="line"></div>
        </div>
        <div className="d-flex gap20 flex-wrap">
          <div className="flex-w40p w100p-lg-down text-left">
            <h3>{t('WEBSITE-BUILDER.TRACK-LINK')}</h3>
            <p className="text-faded mt8">{t('WEBSITE-BUILDER.TRACK-LINK-DESCRIPTION')}</p>
          </div>
          <div className="flex-w60p w100p-lg-down text-left">
            <h5 className="mb8">{t('WEBSITE-BUILDER.SPOTIFY-LINK')}</h5>
            <div className="pos-rel">
              <CustomIcons className="input-prefix-icon" name="link" />
              <input
                className="has-prefix-icon"
                placeholder={t('WEBSITE-BUILDER.PASTE-SPOTIFY-LINK')}
                value={formSpotifyLink ?? ''}
                onChange={(event) => setValue('spotlightRelease.spotifyLink', event.target.value)}
              />
            </div>

            <h5 className="mb8 mt20">{t('WEBSITE-BUILDER.APPLE-MUSIC-LINK')}</h5>
            <div className="pos-rel">
              <CustomIcons className="input-prefix-icon" name="link" />
              <input
                className="has-prefix-icon"
                placeholder={t('WEBSITE-BUILDER.PASTE-APPLE-MUSIC-LINK')}
                value={formAppleLink ?? ''}
                onChange={(event) => setValue('spotlightRelease.appleMusicLink', event.target.value)}
              />
            </div>

            <h5 className="mb8 mt20">{t('WEBSITE-BUILDER.ITUNES-LINK')}</h5>
            <div className="pos-rel">
              <CustomIcons className="input-prefix-icon" name="link" />
              <input
                className="has-prefix-icon"
                placeholder={t('WEBSITE-BUILDER.PASTE-ITUNES-LINK')}
                value={formItunesLink ?? ''}
                onChange={(event) => setValue('spotlightRelease.itunesLink', event.target.value)}
              />
            </div>

            <h5 className="mb8 mt20">{t('WEBSITE-BUILDER.YOUTUBE-MUSIC-LINK')}</h5>
            <div className="pos-rel">
              <CustomIcons className="input-prefix-icon" name="link" />
              <input
                className="has-prefix-icon"
                placeholder={t('WEBSITE-BUILDER.PASTE-YOUTUBE-MUSIC-LINK')}
                value={formYoutubeLink ?? ''}
                onChange={(event) => setValue('spotlightRelease.youTubeMusicLink', event.target.value)}
              />
            </div>

            <h5 className="mb8 mt20">{t('WEBSITE-BUILDER.AMAZON-MUSIC-LINK')}</h5>
            <div className="pos-rel">
              <CustomIcons className="input-prefix-icon" name="link" />
              <input
                className="has-prefix-icon"
                placeholder={t('WEBSITE-BUILDER.PASTE-AMAZON-MUSIC-LINK')}
                value={formAmazonLink ?? ''}
                onChange={(event) => setValue('spotlightRelease.amazonMusicLink', event.target.value)}
              />
            </div>

            <h5 className="mb8 mt20">{t('WEBSITE-BUILDER.DEEZER-LINK')}</h5>
            <div className="pos-rel">
              <CustomIcons className="input-prefix-icon" name="link" />
              <input
                className="has-prefix-icon"
                placeholder={t('WEBSITE-BUILDER.PASTE-DEEZER-LINK')}
                value={formDeezerLink ?? ''}
                onChange={(event) => setValue('spotlightRelease.deezerLink', event.target.value)}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SpotlightReleasePage;
